<template>
  <svg
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.0008 18H12.0108M9.17275 15.172C9.92286 14.4222 10.9401 14.0009 12.0008 14.0009C13.0614 14.0009 14.0786 14.4222 14.8288 15.172M6.34375 12.343C7.08662 11.6001 7.96856 11.0108 8.93919 10.6088C9.90982 10.2067 10.9501 9.99976 12.0008 9.99976C13.0514 9.99976 14.0917 10.2067 15.0623 10.6088C16.0329 11.0108 16.9149 11.6001 17.6577 12.343"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
    />
    <path
      d="M3.51562 9.51501C8.20162 4.82801 15.7996 4.82801 20.5156 9.51501"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
    />
  </svg>
</template>
