<template>
  <svg
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="M2.69566 2.23779L19.9658 19.508C20.2935 19.8357 20.4776 20.2801 20.4776 20.7436C20.4776 21.207 20.2935 21.6515 19.9658 21.9792C19.638 22.3068 19.1936 22.4908 18.7302 22.4908C18.2668 22.4908 17.8223 22.3068 17.4946 21.9792L13.2758 17.6878C12.9997 17.4074 12.8449 17.0299 12.8446 16.6364V16.3772C12.8446 16.1785 12.8052 15.9818 12.7286 15.7985C12.652 15.6152 12.5397 15.4489 12.3983 15.3094L11.8536 14.8064C11.6687 14.6358 11.4439 14.5144 11.1998 14.4535C10.9557 14.3925 10.7002 14.394 10.4567 14.4576C10.0729 14.5578 9.66957 14.5559 9.28672 14.452C8.90388 14.3482 8.55484 14.146 8.27425 13.8656L4.26972 9.86061C1.89409 7.48498 1.01987 3.89764 2.69566 2.23779Z"
        stroke="currentColor"
        stroke-linejoin="round"
        stroke-width="1.5"
      />
      <path
        d="M18.7496 1.5L15.1285 5.12109C14.8499 5.39968 14.6288 5.73043 14.478 6.09445C14.3272 6.45847 14.2496 6.84863 14.2496 7.24266V7.93922C14.2496 8.03777 14.2302 8.13536 14.1925 8.22641C14.1548 8.31746 14.0995 8.40018 14.0298 8.46984L13.4996 9M14.9996 10.5L15.5298 9.96984C15.5994 9.90013 15.6821 9.84484 15.7732 9.80711C15.8642 9.76939 15.9618 9.74998 16.0604 9.75H16.7569C17.151 9.75 17.5411 9.67238 17.9051 9.52158C18.2692 9.37078 18.5999 9.14974 18.8785 8.87109L22.4996 5.25M20.6246 3.375L16.8746 7.125M9.3746 17.25L4.70022 21.9506C4.34861 22.3021 3.87178 22.4996 3.3746 22.4996C2.87742 22.4996 2.40059 22.3021 2.04897 21.9506C1.69747 21.599 1.5 21.1222 1.5 20.625C1.5 20.1278 1.69747 19.651 2.04897 19.2994L5.9996 15.375"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.5"
      />
    </g>
  </svg>
</template>
