<template>
  <svg
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.998 5.00024H3.99805C2.89348 5.00024 1.99805 5.89567 1.99805 7.00024V17.0002C1.99805 18.1048 2.89348 19.0002 3.99805 19.0002H19.998C21.1026 19.0002 21.998 18.1048 21.998 17.0002V7.00024C21.998 5.89567 21.1026 5.00024 19.998 5.00024Z"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.125"
    />
    <path
      d="M3 9.99976L21 9.99976"
      stroke="currentColor"
      stroke-linecap="square"
      stroke-linejoin="round"
      stroke-width="2.25"
    />
    <path
      d="M4.00195 14.0002H10.002"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-width="0.75"
    />
    <path
      d="M4.00195 15.9998H7.00195"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-width="0.75"
    />
  </svg>
</template>
