<template>
  <svg
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 4.00001C4.474 6.25851 3.0065 6.90501 2 6.50001C2.2715 7.41651 4.2215 8.68751 5.4635 9.41901C5.9985 9.73401 6.155 10.4515 5.7825 10.9485C5.101 11.8585 4.1575 13.1445 4 13.5C0.477 19.895 8.241 22.324 12 22C23.072 21.046 22.6775 12.4015 21 9.00001C16.974 15.997 10.7595 11.9575 11 10.5C11.2405 9.04251 12.896 9.33251 13.5 7.00001C14.5065 2.38401 8.187 0.199508 6 4.00001Z"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
    />
    <path
      d="M9.5 15.5C10.75 17.25 14.5 19 17.5 16.5"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
    />
    <path
      d="M8.5 7C9.05228 7 9.5 6.55228 9.5 6C9.5 5.44772 9.05228 5 8.5 5C7.94772 5 7.5 5.44772 7.5 6C7.5 6.55228 7.94772 7 8.5 7Z"
      fill="currentColor"
    />
  </svg>
</template>
